import {
    Avatar,
    Box,
    Grid,
    makeStyles,
    Typography,
  } from '@material-ui/core'
  import useMediaQuery from '@material-ui/core/useMediaQuery'
  import { Store } from '@material-ui/icons'
  import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
  import LocalShippingIcon from '@material-ui/icons/LocalShipping'
  import { AvatarGroup } from '@material-ui/lab'
  import React, { useEffect, useState } from 'react'
  export const useStyles = makeStyles((theme) => ({
    storeMainBox: {
      marginTop: 30,
      marginRight: 5,
      marginLeft: 5,
      maxWidth: '100%',
      cursor: 'pointer',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        padding: '6px 8px 3px 8px',
      },
      borderRadius: '10px',
      marginBottom: '2rem',
      background: '#fff',
      padding: '18px 20px 10px 20px',
      boxShadow: '2px 4px 8px 1px rgba(0, 0, 0, 0.09)',
      '&:hover': {
        boxShadow: '2px 4px 8px 5px rgba(0, 0, 0, 0.09)',
      },
      transition: 'all ease-in-out 0.15s',
    },
    listStoreBox: {
      marginTop: 30,
    },
    listNameStore: {
      fontSize: '32px',
      fontWeight: 'bold',
    },
    headerDetail: {
      padding: 5,
      [theme.breakpoints.down('xs')]: {
        padding: 2,
      },
      flex: 1,
      borderRadius: 5,
    },
    datosEnvioWrap: {
      flex: 1,
    },
    storeBox: {
      backgroundColor: '#E8E8E8',
      borderRadius: '10px',
      height: '39px',
      paddingLeft: 10,
      paddingRight: 10,
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    datosEnvioInfo: {
      justifyContent: 'flex-start',
    },
    datosEnvioInfoText: {
      color: '#898989',
      margin: 0,
      whiteSpace: 'nowrap',
    },
  
    contentDetailBox: {
      backgroundColor: '#fff',
      padding: '.5rem',
    },
    datosNombreText: {
      margin: 0,
      fontSize: 15,
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    datosNombreStore: {
      margin: 0,
      fontSize: '24px',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
      },
    },
  
    footerText: {
      margin: 0,
      padding: '.5rem',
    },
    skeletonBox: {
      margin: '0 auto',
      width: '100%',
      height: '300px',
      padding: 10,
    },
  }))
import { AssemblyIcon } from '../../custom-icons/custom-icons'
//icons de Font Awesome
  // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  // import { faSearch } from '@fortawesome/free-solid-svg-icons';
  //x icons de Font Awesome x
  //styles
  import {formatDateForDelivery,
  formatDeliveredTime,
  getAssemblyStateName,
  getPurchaseOrderBg,
  getPurchaseOrderIconBg,
  getPurchaseOrderLabelBg,
  getShipmentStateName} from '../Utils/myorders/utils'
  
// --- Colour handlers ---- // 

// --- State handlers ---- // 


export const OrderStateHandler = ({ delivered, mediaQuery, shipmentState, shippingDate, assemblyState, delivered_timestamp }) => {
  if(shipmentState === 'arriving' || shipmentState === 'takeaway' || shipmentState === 'delivered' || shipmentState === 'cancelled'){
    return <Grid item >
      <OrderChip
          less620px={mediaQuery}
          label={
              delivered
              ? `Entregado : ${formatDeliveredTime(delivered_timestamp)}`
              : getShipmentStateName(shipmentState)
          }
          secondaryLabel={
              shipmentState === 'assigned' && shippingDate && `${formatDateForDelivery(shippingDate)}`
          }
          bgColor={getPurchaseOrderBg(shipmentState)}
          labelColor={getPurchaseOrderLabelBg(shipmentState)}
          iconBgColor={getPurchaseOrderIconBg(shipmentState)}
          Icon={LocalShippingIcon}
      />
      </Grid>
  }
  
  return <Grid item >
    <OrderChip
        less620px={mediaQuery}
        label={
            delivered
            ? `Entregado : ${formatDeliveredTime(delivered_timestamp)}`
            : getAssemblyStateName(assemblyState)
        }
        secondaryLabel={
            shipmentState === 'assigned' && shippingDate && `${formatDateForDelivery(shippingDate)}`
        }
        bgColor={getPurchaseOrderBg(assemblyState)}
        labelColor={getPurchaseOrderLabelBg(assemblyState)}
        iconBgColor={getPurchaseOrderIconBg(assemblyState)}
        Icon={AssemblyIcon}
    />
</Grid>
}

export default function OrderCard (props) {
  const less620px = useMediaQuery('(min-width:1111px)')
  const defaultClasses = useStyles()



  const { order, title, receivedDate,
    paymentState,
    assemblyState,
    customClasses,
    shipmentState,
    shippingDate,
    delivered,
    delivered_timestamp,
    orderChipSpan,
    id,
    productsPreviewImage,
    addressee, storeName,totalPrice } = props

  const [classes, setDefaultClasses] = useState(defaultClasses || customClasses)
  useEffect(() => {
    if(customClasses){
      return setDefaultClasses(customClasses)
    }
    setDefaultClasses(defaultClasses)
  },[customClasses])
  return (
    
    <Box
      className={classes.storeMainBox}
    >
        <Grid container width={'100%'}
style={{gap:6}}>
            <Grid container justifyContent='space-between'
wrap='nowrap'> 
    
                <Grid item>
                    <Typography variant="h3" className={classes.datosNombreStore}>{title ? title : addressee}</Typography>
                    <Typography variant="p" className={classes.datosEnvioInfoText}>{receivedDate}</Typography>
                </Grid>
            <Grid item >
                <Box className={classes.storeBox}>
                    <Store />
                    <Typography variant="p"
                        className={classes.datosNombreText}
                        style={{whiteSpace: 'nowrap',fontWeight: 600}}>
                        {storeName}
                    </Typography>
                </Box>
            </Grid>
            </Grid>
          <Grid marginLeft='auto' alignItems='flex-end'
                container
                direction='column'>
                <Grid item>
                    <Typography variant='span'>
                            <Typography variant="p" style={{
                                margin: 0,
                                whiteSpace: 'nowrap',
                                color: '#444',
                                fontWeight: 600,
                                fontSize: '14px',
                            }}
                            >
                            {Object.keys(order.items || {})?.length}
                            {Object.keys(order.items || {})?.length > 1
                                ? ' Productos'
                                : ' Producto'}
                            </Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <AvatarGroup max={8}>
                        {productsPreviewImage.map(productPreview => 
                            <Avatar key={productPreview.name} alt={productPreview.alt}
                                    src={productPreview.src}>
                            </Avatar>
                        )}
                    </AvatarGroup>
                </Grid>
            </Grid>      
            <Grid container direction='row'
            justifyContent='space-between'
alignItems='end'>
                <Grid item xs={orderChipSpan}>
                    <Grid
                            direction='column'
                            container
                            style={{
                                gap:'4px'
                            }}
                        >
                            <Grid item xs={'auto'}>
                                <OrderChip
                                    less620px={less620px}
                                    label={paymentState === 'payed' ? 'Pagado' : 'Pago pendiente'}
                                    bgColor={paymentState === 'payed' ? '#bbf7d0' : '#fde68a'}
                                    labelColor={paymentState === 'payed' ? '#052e16' : '#422006'}
                                    iconBgColor={paymentState === 'payed' ? '#dcfce7' : '#fef9c3'}
                                    Icon={AttachMoneyIcon}
                                />
                            </Grid>
                           
                              <OrderStateHandler addressee={addressee} id={id}
delivered={delivered}
mediaQuery={less620px}
shipmentState={shipmentState}
assemblyState={assemblyState}
shippingDate={shippingDate} delivered_timestamp={delivered_timestamp}/>

                    </Grid>
                </Grid>
                <Grid item xs={'auto'}
style={{alignSelf:'end' }}>
                    <Typography variant='p'
                    style={{color: '#565656', marginLeft:'auto' }}
                    className={classes.datosNombreStore}
                >
                    ${totalPrice}
                </Typography>
              </Grid>
            </Grid>
        </Grid>
    </Box>
  )
}

const OrderChip = ({
    Icon,
    label,
    secondaryLabel,
    bgColor,
    iconBgColor,
    iconWidth = '30px',
    labelColor,
    less620px,
  }) => {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '5px',
          maxWidth:'fit-content',
          backgroundColor: bgColor,
          height: '34px',
          gap:8,
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            height: '34px',
            width: iconWidth,
            justifyContent: 'center',
            background: iconBgColor,
          }}
        >
          <Icon
            style={{ color: labelColor, fontSize: less620px ? '18px' : '15px' }}
          />
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column',paddingRight: 8 }}>
          <Typography
            style={{
              fontWeight: 400,
              fontFamily:'system-ui',
              color: labelColor,
              whiteSpace: 'nowrap',
              lineHeight: 1.2,
              fontSize: less620px ? '16px' : '14px',
            }}
          >
            {label}
          </Typography>
          <Typography
            style={{
              whiteSpace: 'nowrap',
              fontSize: less620px ? '12px' : '10px',
              color: labelColor,
              opacity: 80,
              margin: 0,
              lineHeight: 1,
              textAlign: 'center',
            }}
          >
            {secondaryLabel}
          </Typography>
        </Box>
      </Box>
    )
  }