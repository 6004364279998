import moment from 'moment/moment'

// --- Colour handlers ---- // 
const getPurchaseOrderBg = (state) => {
    if(state == 'no') return '#fef08a'

    if(state === 'assembled' || state === 'checked' || state === 'inBoxes') return '#fef08a'
    if(state == 'cancelled') return '#fca5a5'
    if(state == 'arriving') return '#bbf7d0'
    if(state == 'assembling') return '#fef08a'
    if(state == 'takeaway') return '#fef08a'
    if(state === 'delivered') return '#bbf7d0'
    if(state === 'pending' || state === 'assigned') return '#fef08a'
}
const getPurchaseOrderLabelBg = (state) => {
    if(state === 'assembled' || state === 'checked' || state === 'inBoxes') return '#422006'
    if(state == 'no') return '#422006'
    if(state == 'arriving') return '#052e16'
    if(state == 'assembling') return '#422006'
    if(state == 'cancelled') return '#450a0a'
    if(state === 'delivered') return '#052e16'
    if(state === 'pending' || state === 'assigned') return '#422006'
    if(state === 'takeaway') return '#422006'
}
const getPurchaseOrderIconBg = state => {
    if(state === 'assembled' || state === 'checked' || state === 'inBoxes') return '#fef9c3'
    if(state == 'no') return '#fef9c3'
    if(state === 'assembling') return '#fef9c3'
    if(state == 'arriving') return '#dcfce7'
    if(state === 'cancelled') return '#fee2e2'
    if(state === 'delivered') return '#dcfce7'
    if(state === 'pending' || state === 'assigned') return '#fef9c3'
    if(state === 'takeaway') return '#fef9c3'
}
// --- Colour handlers ---- // 


// --- Time Formatters ---- // 
const formatDeliveredTime = (date) => moment(new Date(date * 1000)).format('DD/MM/yyyy HH:mm')
const formatDateForDelivery = (date) => moment(new Date(date + ' GMT-0300')).format('DD/MM/yyyy HH:mm')
// --- Time Formatters ---- // 


// --- State handlers ---- // 
const getShipmentStateName = (state) => {
    if(state === 'pending' || state === 'assigned') return 'Para enviar'
    if(state === 'arriving') return 'En camino'
    if(state === 'delivered') return 'Entregado'
    if(state === 'takeaway') return 'Para retirar'
    if(state === 'cancelled') return 'Cancelada'
}

const getAssemblyStateName = (state) => {
    if(state === 'assembled' || state === 'checked' || state === 'inBoxes') return 'Analizando el estado del envío'
    if(state === 'no') return 'En espera'
    if(state === 'assembling') return 'En preparación'
}

export {
    formatDateForDelivery,
    formatDeliveredTime,
    getAssemblyStateName,
    getPurchaseOrderBg,
    getPurchaseOrderIconBg,
    getPurchaseOrderLabelBg,
    getShipmentStateName
}