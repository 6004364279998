// import Carousel, { arrowsPlugin, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css'
import 'react-multi-carousel/lib/styles.css'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/styles'
import { omitBy, shuffle, sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
// import { isMobile } from "react-device-detect";
// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-multi-carousel'
import { useHistory } from 'react-router-dom'

import { algoliaIndex } from '../../algolia'
import config from '../../config'
import { useMarketContext } from '../../context/MarketContext'
import { usePriceListIDContext } from '../../context/PriceListContext'
import { useUserData } from '../../context/UserContext'
import ProductCard from './ProductCard'

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: '#8ba130',
        width: '100%',
        maxWidth: 1400,
        margin: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '5px'
        }
    },
    titleRelacionados: {
        marginBottom: theme.spacing(1),
        textAlign: 'center',
        '&> h2': {
            // color: '#fff',
            marginBottom: '10px'
        },
        '& > span': {
            display: 'inline',
        }
    },
    lineaRelacionados: {
        color: '#fff',
        borderBottom: '3px solid #fff',
        width: '120px',
        textAlign: 'center',
        margin: '0 auto'
    },
    viewMoreButtonContainer: {
        margin: theme.spacing(2),
        transition: 'all 0.3s ease-in-out',
    },
    viewMoreButton: {
        margin: theme.spacing(1),
        border: 'none',
        borderRadius: '2.65rem',
        color: '#4A4A4A',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#4A4A4A',
            color: '#fff'
        }
    },
}))

const fetchProducts = async ({ query = '',
    facets = [],
    filters = '',
    facetFilters = '',
    offset = 0,
    length = 0,
    hitsPerPage = 0,
    userID,
    index = 'products' }) =>
    fetch('https://us-central1-vivet-web.cloudfunctions.net/search',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(omitBy({
                query,
                facets,
                filters,
                userID,
                facetFilters,
                offset,
                length,
                hitsPerPage
            }, v => !v))
        }).then(r => r.ok ? r.json() :
            algoliaIndex(index).search(query, omitBy({
                maxValuesPerFacet: 200,
                facets,
                filters,
                facetFilters,
                offset,
                length,
                hitsPerPage
            }, v => !v)))


export default function RelatedProducts({ products, facetFilters, title, showMoreLink }) {
    const classes = useStyles()
    const [hits, setHits] = useState([])
    const { seller } = useMarketContext()
    const priceListID = usePriceListIDContext()
    const mobile = useMediaQuery('(max-width:600px)')
    const history = useHistory()
    const { onlyDry, uid } = useUserData()

    const facetFiltersString = typeof facetFilters === 'string' ? facetFilters : JSON.stringify(facetFilters || [])

    !products && (products = hits)

    useEffect(() => {
        let active = true
        const facetFiltersObject = JSON.parse(facetFiltersString)

        facetFiltersObject && fetchProducts({
            userID: uid,
            facetFilters: JSON.stringify(['nostock:false', 'active:true', onlyDry && config.onlyDry && 'conservation:seco', ['showAll:true', 'showOnly:' + priceListID], ...facetFiltersObject]),
            hitsPerPage: mobile ? 10 : 40,
        }).then(({ hits }) =>
            active && setHits(sortBy(shuffle(hits), [`discounts.${priceListID}`]).reverse()?.slice(0, mobile ? 4 : hits.length))
        )

        return () => active = false
    }, [facetFiltersString, mobile, seller, priceListID])

    return (
        <>
            {hits?.length > 0 ?
                <div className={classes.root}>
                    <div className={classes.titleRelacionados}>
                        <h2>{title} {!mobile &&
                            <Button onClick={() => history.push(showMoreLink)}
                                color={'primary'}
                                className={classes.viewMoreButton}>Ver todas</Button>}</h2>
                        <div className={classes.lineaRelacionados}></div>
                    </div>

                    {mobile && <Grid container>
                        {(products || hits).map((value) => (
                            <Grid item
                                xs={6}
                                key={value.objectID}>
                                {!value.nostock ? <ProductCard product={value}
                                    showAdd={true} /> : null}
                            </Grid>))}
                        <Grid item
                            xs={12}
                            className={classes.viewMoreButtonContainer}>
                            {showMoreLink && <Button onClick={() => history.push(showMoreLink)}
                                variant={'contained'}
                                color={'primary'}
                                fullWidth >Ver más</Button>}
                        </Grid>
                    </Grid>}
                    {!mobile && <Carousel responsive={responsive}
                        infinite={false}
                        centerMode={false}
                        slidesToSlide={3}
                        speed={500}>
                        {(products || hits).map((value) => (
                            !value.nostock ? <ProductCard key={value.objectID}
                                product={value}
                                showAdd={true} /> : null))}
                    </Carousel>
                    }

                </div> : null}
        </>
    )
}


