import { Avatar, Box, makeStyles, MenuItem, Select, Typography, useMediaQuery } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'

import imgurWebp from '../../components/Utils/imgurWebp'
import { useCollection } from '../../firebase/database'
import { formatTextToUrl } from '../../lib/formatTextToUrl'

const LIMIT = 10

const useStyles = makeStyles(() => ({
  scrollContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    overflowX: 'auto',
    maxWidth: 800,
    width: '100%',
    paddingBottom: 8,
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.2)',
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      borderRadius: 4,
    },
  },
  avatar: {
    cursor: 'pointer',
    minWidth: '100px',
    height: '100px',
    '&:hover': {
      opacity: 0.8,
    },
  },
  avatarInSelect: {
    cursor: 'pointer',
    minWidth: '40px',
    height: '40pxpx',
  },
  avatarSelected: {
    border: '1px solid black',
    '&:hover': {
      opacity: 1,
      cursor: 'auto',
    },
  },
  weightCard: {
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: 6,
    padding: '2px 4px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
    marginTop: 3,
  },
  weightCardSelected: {
    border: '1px solid black',
    borderRadius: 6,
    padding: '2px 4px',
    '&:hover': {
      opacity: 1,
      cursor: 'auto',
    },
  },
  weight: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
  notavailable: {
    opacity: 0.5,
  },
}))

export const Variants = ({ product }) => {
  const { name, brand, objectID, taste, quantity } = product
  const classes = useStyles()
  let { productId } = useParams()

  const [products, isLoading] = useCollection({
    collection: 'products',
    where: [
      ['brand', '==', brand],
      ['name', '==', name],
      ['active', '==', true],
    ],
    limit: LIMIT,
  })

  const variantsInTaste = products
  const isDesktop = useMediaQuery('(min-width:950px)')

  const isEmptyVariantInTaste = !variantsInTaste?.length || variantsInTaste.length === 1
  const isSomeVariantWithDiffInQuantity = quantity && products?.some((variant) => variant.quantity !== quantity)
  const isSomeVariantInTaste = quantity && products?.some((variant) => variant.taste && variant.taste !== taste)

  if (isLoading) {
    return null
  }

  const sortedVariants = variantsInTaste?.sort((a, b) => Number(a.nostock) - Number(b.nostock))

  return (
    <>
      {!isEmptyVariantInTaste && (
        <Box
          className={classes.scrollContainer}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            overflow: 'auto',
            maxWidth: 800,
            width: '100%',
          }}
        >
          {variantsInTaste?.length > 4 && isDesktop ? (
            <Select
              value={productId}
              defaultValue={productId}
              id="variantSelector"
            >
              {sortedVariants?.map((variant) => (
                <MenuItem
                  key={variant?.objectID}
                  value={variant?.objectID}
                >
                  <Link
                    key={variant.objectID}
                    className={classes.link}
                    to={`/app/product/${formatTextToUrl(variant?.name) + '-' + formatTextToUrl(variant?.taste)}/${variant.objectID}`}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Avatar
                        className={[classes.avatarInSelect, variant?.nostock ? classes.notavailable : '']}
                        variant="square"
                        alt={variant?.taste || name}
                        src={imgurWebp(variant?.image, 40)}
                        style={{ borderRadius: 6 }}
                      />
                      {(!!isSomeVariantWithDiffInQuantity || !!isSomeVariantInTaste) && (
                        <Box
                          sx={{ paddingLeft: 4 }}
                          className={variant?.nostock ? classes.notavailable : ''}
                        >
                          <Typography className={classes.weight}>
                            {!!isSomeVariantInTaste && variant.taste}
                            {!!isSomeVariantInTaste && !!isSomeVariantWithDiffInQuantity ? ' - ' : ''}
                            {!!isSomeVariantWithDiffInQuantity && `${variant.quantity}${variant.unit}`}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Link>
                </MenuItem>
              ))}
            </Select>
          ) : (
            variantsInTaste.map((variant) => (
              <Link
                key={variant.objectID}
                className={classes.link}
                to={`/app/product/${formatTextToUrl(variant?.name) + '-' + formatTextToUrl(variant?.taste)}/${variant.objectID}`}
              >
                <Avatar
                  className={[
                    classes.avatar,
                    objectID === variant.objectID ? classes.avatarSelected : '',
                    variant?.nostock ? classes.notavailable : '',
                  ]}
                  variant="square"
                  alt={variant?.taste || name}
                  src={imgurWebp(variant?.image, 120)}
                  style={{ borderRadius: 6 }}
                />
                {(!!isSomeVariantWithDiffInQuantity || !!isSomeVariantInTaste) && (
                  <Box className={[classes.weightCard, objectID === variant.objectID ? classes.weightCardSelected : '']}>
                    <Typography className={classes.weight}>
                      {!!isSomeVariantInTaste && variant.taste}
                      {!!isSomeVariantInTaste && !!isSomeVariantWithDiffInQuantity ? ' - ' : ''}
                      {!!isSomeVariantWithDiffInQuantity && `${variant.quantity}${variant.unit}`}
                    </Typography>
                  </Box>
                )}
              </Link>
            ))
          )}
        </Box>
      )}
    </>
  )
}
