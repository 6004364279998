import { Box, Button, CircularProgress, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import getObjects from '../../algolia/getObjects'
import { db } from '../../firebase/database'
import { useFetchCursor } from '../../firebase/hooks/useFetchCursor'
import OrderCard from '../OrderCard/OrderCard'
import { findProductsPreview,formatProductsPreview } from '../Utils/formatProductsPreview'
import { numberWithCommas } from '../Utils/NumberWithCommas'
const options = { year: 'numeric', month: 'long', day: 'numeric' }
const useStyles = makeStyles((theme) => ({
  skeletonBox: {
    width: '450px',
    padding: 10,
    height: 215,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const StoreOrders = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [cursor, setCursor] = useState(true)
  const [orders, isLoading, isEnd] = GetOrders(cursor)
  const [products, setProducts] = useState([])
  const itemsArray = !isLoading ? orders.map((o) => o.itemsArray).flat() : []
  const productsObjPromise  = getObjects('products', itemsArray)
  const less970px = useMediaQuery('(min-width:970px)')

  useEffect(() => {
    if(Object.values(products || []).length > 0) return;
    (async () => {
      const promise = await productsObjPromise
      setProducts(promise)
    })()
  }, [productsObjPromise])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <Box>
      {/* --- Loading Skeleton --- */}
      {loading && orders.map((order, i) => (
          <Box key={i}
            pt={0.8}
            className={classes.skeletonBox}>
            <Skeleton
              animation="wave"
              variant="rect"
              height={120}
              width="100%"
            />{' '}
            <Skeleton width="100%" />
          </Box>
        ))}
      {/* --- Loading Skeleton --- */}

      {/* --- Order Cards --- */}
      {!loading && orders.map((order) => {
        const productsPreviewImage = formatProductsPreview(findProductsPreview(products, order.itemsArray))
        const receivedDateFormatted = new Date(order?.timestamp?.seconds * 1000).toLocaleDateString('es-ES', options)

        return <Box key={order.id} maxWidth={{  xs:'100%', lg: '450px' }}>
          <Link style={{ textDecoration:'auto', color:'inherit' }} to={`/app/order/${order.id}`}>
              <OrderCard
                id={order.id}
                orderChipSpan={less970px ? 4 : 2}
                productsPreviewImage={productsPreviewImage}
                totalPrice={numberWithCommas(order.total || order.subTotal * 1.21, 0)}
                receivedDate={receivedDateFormatted}
                storeName={order?.data?.store?.storeName}
                assemblyState={order.assemblyState}
                shipmentState={order.shipmentState}
                delivered_timestamp={order.delivered_timestamp}
                shippingDate={order.shippingDate}
                delivered={order.delivered}
                paymentState={order.state}
                addressee={order.addressee} 
                title={order.data.user.name}
                order={order}
                />
            </Link>
          </Box>})
      }
      {/* --- Order Cards --- */}

      {!isEnd && orders?.length ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => setCursor(!cursor)}
            variant="contained"
            style={{ marginBottom: 10 }}
            color="primary"
          >
            {isLoading ? <CircularProgress /> : 'Ver más'}
          </Button>
        </div>
      ) : (
        <Box style={{ margin: 'auto', marginTop: 100 }}>
          {isLoading && <CircularProgress />}
        </Box>
      )}
    </Box>
  )
}

function GetOrders(cursor) {
  const history = useHistory()
  const locId = history.location.pathname.split('/').pop()

  const { data: orders, isLoading, isEnd } = useFetchCursor({
    query:
      db.collection('orders')
        .where('store', '==', locId)
        .orderBy('timestamp', 'desc')
    ,
    cursor,
    enabled: locId
  })

  return [orders, isLoading, isEnd]
}
