import {Avatar, Box, Breadcrumbs, Button, Chip, ListItem, Typography} from '@material-ui/core'
import {Divider} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import React, {useState} from 'react'
import ReactMarkdown from 'react-markdown'
// import RelatedProducts from "./RelatedProducts";
import {Link, useHistory} from 'react-router-dom'

import {AddFavoriteDetail} from '../../components/Product/AddFavoriteDetail'
import DetailPrice from '../../components/Product/DetailPrice'
import GetPrices from '../../components/Product/Price/Price'
import ProductAggregationDetail from '../../components/Product/ProductAggregationDetail'
import ProductAggregationPack from '../../components/Product/productAggregationPack'
import imgurWebp from '../../components/Utils/imgurWebp'
import {numberWithCommas} from '../../components/Utils/NumberWithCommas'
import config from '../../config/config'
import {HideHeader} from '../../context/LayoutContext'
import {usePriceList} from '../../context/PriceListContext'
import {useUserData} from '../../context/UserContext'
import {useStores} from '../../context/UserContext'
import ProductThumbnails from '../../pages/product/ProductThumbnails'
import {BuyPackMessage} from './BuyPackMessage'
import {Variants} from './Variants'

const useStyles = makeStyles((theme) => ({
  boxDetail: {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    marginTop: 40,
    maxWidth: '1000px',
    padding: 10,
  },
  boxDetail2: {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1400px',
    padding: 10,
  },
  boxDescription: {
    padding: '10px',
    paddingTop: '1px',
    paddingLeft: '20px',
    borderRadius: 10,
    backgroundColor: '#fff',
    border: '1px solid #CBC5C5',
    borderTop: 'none',
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
  },
  boxProduct: {
    display: 'flex',
    gap: 40,
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    borderBottom: 'none',
    justifyContent: 'flex-start',
    borderRadius: 10,
    border: '1px solid #CBC5C5',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: ' 0px 6px 7px 0px rgba(0,0,0,0.1)',
    padding: '1rem',
    paddingTop: '2rem',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  dontDescription: {
    display: 'flex',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    borderBottom: 'none',
    justifyContent: 'flex-start',
    gap: 10,
    borderRadius: 10,
    border: '1px solid #CBC5C5',
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#fff',
    boxShadow: ' 0px 6px 7px 0px rgba(0,0,0,0.1)',
    padding: '1rem',
    paddingTop: '2rem',
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  product_name: {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    margin: '0',
    padding: '1rem',
    paddingBottom: '0'
  },
  product_quantity: {
    padding: '.1rem .4rem',
    textAlign: 'center',
    backgroundColor: '#ccc',
    fontSize: '.8rem',
    borderRadius: 3,
    color: 'black',
    marginLeft: '1rem',
  },
  product_taste: {
    padding: '.1rem .4rem',
    backgroundColor: 'black',
    borderRadius: 3,
    fontSize: '.8rem',
    color: '#fff',
    marginLeft: '.2rem',
  },
  imgBrandLogo: {
    width: '2rem',
    height: '2rem',
    marginRight: '.5rem',
  },
  productBrand: {
    fontWeight: 'bold',
    display: 'flex',
    textDecoration: 'unset',
    color: 'unset',
    margin: '1rem',
  },
  sidebar: {
    position: 'relative',
    marginLeft: '1rem',
    width: '400px',
    maxHeight: 500,
    margin: '0 auto',
    top: '30px',
    borderRadius: 8,
    [theme.breakpoints.down(950)]: {
      width: '100%',
      maxWidth: 700,
      margin: '1rem',
    },
  },
  sidebarText: {
    maxWidth: '200px',
    marginLeft: '1rem',
  },
  sidebarNotifications: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(950)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  productName_box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 5,
    flex: 1,
    width: '100%',
    [theme.breakpoints.down(950)]: {
      maxWidth: 1500,
    },
  },
  buttonPack: {
    backgroundColor: '#ccc',
    width: '100%',
    margin: '0 10px 10px 10px',
    maxWidth: '170px',
  },
  productSpecsBox: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    top: 15,
    left: 10,
    maxWidth: 500,
  },

  pricePerUnit: {
    marginLeft: 30,
    fontSize: '1rem',
  },
  listContainerIngredients: {
    maxHeight: 50,
    marginTop: 40,
  },
  listContainerIngredientsCanContain: {
    maxHeight: 50,
    marginTop: 150,
  },

  listIngredient: {
    padding: 0,
    marginLeft: 20,
    fontSize: 12,
  },
  titleIngredient: {
    marginLeft: 10,
    fontWeight: 'bold',
    fontSize: 15,
  },

  buttonIngredients: {
    maxHeight: 20,
    marginTop: 150,
  },

  listContainerIngredientsSeeMore: {
    marginTop: 40,
    marginLeft: 5,
  },
  listContainerIngredientsSeeLees: {
    marginTop: 0,
    marginLeft: 5,
  },

  titleIngredients: {
    marginLeft: 10,
    fontWeight: 'bold',
    fontSize: 15,
    marginTop: -30,
  },
  divider: {
    marginTop: 30,
  },
}))

export default function ProductDescription({product, product_populate}) {
  const classes = useStyles()
  const history = useHistory()
  const {isAnonymous} = useUserData()
  const priceList = usePriceList()
  const [pack, setPack] = useState(true)
  const [open, setOpen] = useState(true)
  const stores = useStores()
  const showPrices = !config.show_prices_only_validated_store || stores?.some?.((s) => s.validated)

  //Ingredientes

  const [packIngredient, setpackIngredient] = useState(false)

  // Volver a mostrar el header seleccionado el producto
  HideHeader(false)

  const price = GetPrices({product: product})
  const pricePerUnit =
    ['g', 'ml', 'u', 'kg', 'l'].includes(product.unit) &&
    product.quantity !== 1 &&
    ((price.price * (1 - price.discount)) / product.quantity) * (['g', 'ml'].includes(product.unit) ? 1000 : 1)
  return (
    <Box
      style={{
        maxWidth: config.show_prices_only_validated_store && isAnonymous ? 1000 : 1400,
      }}
      className={priceList.headerMessage ? classes.boxDetail : classes.boxDetail2}
    >
      <Box className={showPrices ? classes.boxProduct : classes.dontDescription}>
        <Box style={{position: 'relative', zIndex: '100', cursor: 'zoom-in'}}>
          <Breadcrumbs
            style={{
              background: '#fff',
              width: 'fit-content',
              margin: 'auto',
            }}
            separator="|"
            aria-label="breadcrumb"
          >
            <Typography
              onClick={() => history.push('/app/')}
              style={{cursor: 'pointer'}}
            >
              Inicio
            </Typography>
            <Typography
              onClick={() => history.push('/app/products')}
              style={{cursor: 'pointer'}}
            >
              Productos
            </Typography>
            <Typography
              onClick={() => history.push('/app/products')}
              style={{cursor: 'pointer'}}
            >
              {product_populate.specs?.[0]?.name}
            </Typography>
          </Breadcrumbs>
          <ProductThumbnails product={product_populate}/>
        </Box>
        <Box className={classes.productName_box}>
          <Box display={'flex'}>
            <h1 className={classes.product_name}>{product_populate.name}</h1>
            {!isAnonymous && (
              <AddFavoriteDetail
                product={product}
                pointer="pointer"
              />
            )}
          </Box>
          <Box display={'flex'}>
            <Box
              className={classes.product_quantity}>{product_populate.quantity ? product_populate.quantity + product_populate.unit : ''}</Box>
            {product_populate.taste && <Box className={classes.product_taste}>{product_populate.taste}</Box>}
          </Box>
          <Link
            className={classes.productBrand}
            to={'/app/products?brand=' + product_populate.brand.objectID}
          >
            {product_populate.brand.image && (
              <img
                className={classes.imgBrandLogo}
                alt="brandLogo"
                src={imgurWebp(product_populate.brand.image)}
              />
            )}
            {product_populate.brand.name}
          </Link>
          <Box className={classes.containerProductIngredient}>
            {showPrices ? (
              <DetailPrice
                product={product}
                position={true}
              />
            ) : null}
            {showPrices
              ? pricePerUnit &&
              !product.box && (
                <p className={classes.pricePerUnit}>
                  (${numberWithCommas(pricePerUnit, 0)}/{product.unit === 'g' ? 'Kg' : product.unit === 'ml' ? 'L' : product.unit})
                </p>
              )
              : null}
            <Box className={classes.productSpecsBox}>
              {product_populate?.specs
                ?.filter((item) => item.showOnProduct)
                .map((item) => (
                  <Chip
                    key={item.name}
                    variant="outlined"
                    label={item.name}
                    avatar={<Avatar src={item.image ? item.image : item.image}>{item.name[0]}</Avatar>}
                  />
                ))}
            </Box>

            {product_populate.ingredients && (
              <>
                <Divider className={classes.divider}/>
                <Box className={classes.listContainerIngredients}>
                  <Typography className={classes.titleIngredients}>Ingredientes</Typography>
                  {product_populate?.ingredients?.slice?.(0, packIngredient ? 100 : 4)?.map?.((ingredient) => (
                    <ListItem
                      key={ingredient}
                      className={classes.listIngredient}
                    >
                      {ingredient}
                    </ListItem>
                  ))}
                </Box>
                {packIngredient && (
                  <>
                    <Box className={classes.listContainerIngredientsCanContain}>
                      <Typography className={classes.titleIngredient}>Puede contener</Typography>
                      {product_populate?.canContains?.map?.((ingredient) => (
                        <ListItem
                          key={ingredient}
                          className={classes.listIngredient}
                        >
                          {ingredient}
                        </ListItem>
                      ))}
                    </Box>

                    <Box className={classes.listContainerIngredientsContain}>
                      <Typography className={classes.titleIngredient}>Contiene</Typography>
                      {product_populate?.contains?.map?.((ingredient) => (
                        <ListItem
                          key={ingredient}
                          className={classes.listIngredient}
                        >
                          {ingredient}
                        </ListItem>
                      ))}
                    </Box>
                  </>
                )}

                {packIngredient ? (
                  <Button
                    onClick={() => setpackIngredient((a) => !a)}
                    className={classes.listContainerIngredientsSeeLees}
                    color="secondary"
                  >
                    Ver Menos
                  </Button>
                ) : (
                  <Button
                    onClick={() => setpackIngredient((a) => !a)}
                    className={classes.listContainerIngredientsSeeMore}
                    color="secondary"
                  >
                    Ver Más
                  </Button>
                )}
              </>
            )}
          </Box>
          <Box style={{marginTop: 40, marginLeft: 0, width: '100%'}}>
            <Variants product={product}/>
          </Box>
        </Box>
        {showPrices ? (
          <Box
            border="1px solid #ccc"
            className={classes.sidebar}
            p={2}
          >
            <Box className={classes.sidebarNotifications}>
              {!priceList.purchaseMinRequired && (
                <Box
                  display={'flex'}
                  alignItems="center"
                >
                  <LocalShippingOutlinedIcon color="black"/>
                  <p className={classes.sidebarText}>Comprando $ {numberWithCommas(priceList.purchasemin, 0)} o más
                    obtenes envio gratis</p>
                </Box>
              )}
              {!product.nostock && (
                <Box
                  display={'flex'}
                  alignItems="center"
                >
                  <LocalMallOutlinedIcon color="black"/>
                  <p className={classes.sidebarText}>Stock Disponible</p>
                </Box>
              )}
            </Box>
            <Box style={{padding: 0}}>
              {pack && (
                <ProductAggregationDetail
                  product={product_populate}
                  setOpen={setOpen}
                />
              )}

              {product_populate.pack > 1 && (!product.available || product.available >= product.pack) && open ? (
                <>
                  <ProductAggregationPack
                    product={product_populate}
                    setOpen={setPack}
                  />
                  <BuyPackMessage product={product}/>
                </>
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box className={showPrices ? classes.boxDescription : classes.boxDescription}>
        <ReactMarkdown>{product_populate.description}</ReactMarkdown>
      </Box>
    </Box>
  )
}

// imgBrandLogo: {
//         width: "2rem",
//         height: "2rem",
//         marginRight: "1rem",
//     },
//     productBrand: {
//         fontWeight: "bold",
//         textDecoration: 'unset',
//         color: 'unset',
//         margin: '1rem'
//     },
//     productName: {
//         fontWeight: "bold",
//         fontSize: "large",
//     },
//     productAgg: {
//         width: "200px",
//     },
//     productPrice: {
//         width: "fit-content",
//     },
//     bestSeller: {
//         textAlign: "center",
//         width: "fit-content",
//         backgroundColor: "#FF7F50",
//         borderRadius: "10px",
//         color: "white",
//         padding: "5px",
//     }

// <Paper style={{maxWidth: "1200px", margin: "0 auto", position:'relative'}}>
//             <Grid container spacing={2}>
//                 <Grid item xs={6}>
//                     <Breadcrumbs style={{
//                         // background: "#D3D3D3",
//                         width: "fit-content",
//                         margin: "auto",
//                     }} separator="|" aria-label="breadcrumb">
//                         <Typography onClick={()=>history.push('/app/')} style={{cursor:'pointer'}}>Inicio</Typography>
//                         <Typography onClick={()=>history.push('/app/products')} style={{cursor:'pointer'}}>Productos</Typography>
//                         {/*<Box fontWeight="fontWeightBold">{product.category}</Box>*/}
//                     </Breadcrumbs>

//                     <ProductThumbnails product={product_populate} />
//                     <ProductSpecs specs={product_populate.specs} />
//                 </Grid>
//                 <Grid item xs={6}>
//                     <Grid container spacing={0}>
//                         <Grid item xs={12}>
//                             <div>
//                                 <Link className={classes.productBrand} to={'/app/products?brand='+product_populate.brand.objectID}>
//                                     <img className={classes.imgBrandLogo} alt="brandLogo" src={product_populate.brand.image} />
//                                     {product_populate.brand.name}
//                                 </Link>
//                                 <br/>
//                                 <div className={classes.productName}>{product_populate.name}</div>
//                                 <Typography align='center' variant="subtitle2" color="textSecondary" component="p">
//                                     {product_populate.quantity ? (product_populate.quantity + product_populate.unit) : ''}
//                                 </Typography>
//                                 <Typography align='center' variant="subtitle2" color="textSecondary" component="p">
//                                     {product_populate.taste}
//                                 </Typography>
//                                 {product_populate.specs.includes('bestSeller') && <div className={classes.bestSeller}>MAS VENDIDO</div>}
//                                 <br/>
//                                 <div className={classes.productPrice}>
//                                     <ProductPrice product={product} />
//                                 </div>
//                                 <br></br>
//                                 <div className={classes.productAgg}>
//                                     <ProductAggregation product={product_populate} />
//                                     <AddFavorite product={product} pointer={'pointer'}/>
//                                 </div>
//                                 <br></br>
//                               <ReactMarkdown>{markdown}</ReactMarkdown>
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </Grid>

//             {/*<RelatedProducts products={product.relatedProducts} />*/}
//         </Paper>
