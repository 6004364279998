import {
  Box,
  Button,
  CircularProgress,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
//icons de Font Awesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
//x icons de Font Awesome x
//styles
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Skeleton } from '@material-ui/lab'
import React, {
  useEffect,
  useState,
  // useEffect
} from 'react'
import {
  Link,
  Route,
  Switch, //useHistory
} from 'react-router-dom'

import getObjects from '../../algolia/getObjects'
import OrderCard from '../../components/OrderCard/OrderCard'
import { findProductsPreview,formatProductsPreview } from '../../components/Utils/formatProductsPreview'
import { numberWithCommas } from '../../components/Utils/NumberWithCommas'
import { useChangeTitle } from '../../components/Utils/useChangeTitle'
import config from '../../config/config'
import { useUserData } from '../../context/UserContext'
import { db } from '../../firebase'
import { useFetchCursor } from '../../firebase/hooks/useFetchCursor'
import ProfileNav from '../profile/ProfileNav'
import OrderDetails from './OrderDetails'

// import Typography from "@material-ui/core/Typography";

export const useStyles = makeStyles(() => ({
  listStoreBox: {
    marginTop: 30,
  },
  skeletonBox: {
    margin: '0 auto',
    width: '100%',
    height: '300px',
    padding: 10,
  },
}))

//components

export default function MyOrders() {
  config?.plant_web_view === true
    ? useChangeTitle(' Mis Ordenes - Plant')
    : useChangeTitle(' Mis Ordenes - Plant business')
  return (
    <Switch>
      <Route path="/app/myorders"
        exact
        component={MyOrdersList} />
      <Route path="/app/myorders/:orderId">
        <OrderDetails />
      </Route>
    </Switch>
  )
}

const options = { year: 'numeric', month: 'long', day: 'numeric' }

function MyOrdersList() {
  const classes = useStyles()
  const [cursor, setCursor] = useState(false)
  const [products, setProducts] = useState(null)
  const [orders, isLoading, isEnd] = GetOrders(cursor)
  const itemsArray = !isLoading ? orders.map((o) => o.itemsArray).flat() : []
  const productsObjPromise  = getObjects('products', itemsArray)

  useEffect(() => {
    if(Object.values(products || []).length > 0) return;
    (async () => {
      const promise = await productsObjPromise
      setProducts(promise)
    })()
  }, [productsObjPromise])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const less950px = useMediaQuery('(min-width:950px)')
  const less1024px = useMediaQuery('(min-width:1024px)')
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 4,
        width:'100%',
        marginTop: less950px ? '20px' : '2px',
        marginRight: less1024px ? '12px' : '2px',
      }}
      sx={{ '& ::-webkit-scrollbar': { display: 'none' } }}
    >
      {less950px ? <ProfileNav /> : <></>}
      <div
        style={{
          display: 'flex',
          background: '#F4F4F4',
          width: less950px ? '90%' : '98%',
          maxHeight: '90vh',
          marginLeft: less1024px ? 40 : 5,
          marginRight: less1024px ? 40 : 5,
          borderRadius: '32px',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            paddingLeft: less1024px ? 24 : 0,
            paddingRight: less1024px ? 24 : 0,
            // margin: '0 auto',
            width:'100%'
          }}
        >
          {!!orders?.length && (
            <Box className={classes.listStoreBox}>
              <ListItemText>
                <h3>Mis Compras</h3>
              </ListItemText>
            </Box>
          )}
          {loading
            && orders.map((order, i) => (
              <Box key={i}
                pt={0.8}
                className={classes.skeletonBox}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={180}
                  width="100%"
                />{' '}
                <Skeleton width="100%" />
              </Box>
            ))}

            {!loading && orders.map((order) => {
                const productsPreviewImage = formatProductsPreview(findProductsPreview(products, order.itemsArray))
                const receivedDateFormatted = new Date(order?.timestamp?.seconds * 1000).toLocaleDateString('es-ES', options)

            
                return <Box key={order.id} maxWidth={{ lg: '100%' }}>
                <Link style={{ textDecoration:'auto', color:'inherit' }} to={`/app/order/${order.id}`}>
                  <OrderCard
                    id={order.id}
                    orderChipSpan={less950px ? 2 : 5}
                    productsPreviewImage={productsPreviewImage}
                    totalPrice={numberWithCommas(order.total || order.subTotal * 1.21, 0)}
                    receivedDate={receivedDateFormatted}
                    storeName={order?.data?.store?.storeName}
                    assemblyState={order.assemblyState}
                    shipmentState={order.shipmentState}
                    delivered_timestamp={order.delivered_timestamp}
                    shippingDate={order.shippingDate}
                    delivered={order.delivered}
                    paymentState={order.state}
                    addressee={order.addressee} 
                    title={order.data.user.name}
                    order={order}
                 />
                 </Link>
                 </Box>})
            }
          {!isEnd && orders?.length ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setCursor(!cursor)}
                variant="contained"
                style={{ marginBottom: 10 }}
                color="primary"
              >
                {isLoading ? <CircularProgress /> : 'Ver más'}
              </Button>
            </div>
          ) : (
            <Box style={{ margin: 'auto', marginTop: 100 }}>
              {isLoading && <CircularProgress />}
            </Box>
          )}
        </div>
      </div>
    </Box>
  )
}


function GetOrders(cursor) {
  const { uid } = useUserData()

  const { data: orders, isLoading, isEnd } = useFetchCursor({
    query:
      db.collection('orders')
        .where('user', '==', uid)
        .orderBy('timestamp', 'desc')
    ,
    cursor,
    enabled: uid
  })

  return [orders, isLoading, isEnd]
}



export const demoOrder = {
  id: 'id1',
  priceList: 'g',
  address: 'XIitHLuMchAxLG8eTD0e',
  total: 1115,
  onlinePayment: true,
  user: 'RZhwHX9RU8YQwoSFybs07uDm2Vy2',
  shippings: ['ITZDTsFT0HdpDD3Rxaf6'],
  data: {
    user: {
      name: 'Mica',
    },
    address: {
      details: '5, c ',
      name: 'Av. Cnel. Niceto Vega 5656',
      address_components: [
        {
          types: ['street_number'],
          short_name: '5656',
          long_name: '5656',
        },
        {
          short_name: 'Av. Cnel. Niceto Vega',
          long_name: 'Avenida Coronel Niceto Vega',
          types: ['route'],
        },
        {
          long_name: 'Palermo Hollywood',
          short_name: 'Palermo Hollywood',
          types: ['neighborhood', 'political'],
        },
        {
          long_name: 'Palermo',
          types: ['sublocality_level_1', 'sublocality', 'political'],
          short_name: 'Palermo',
        },
        {
          types: ['administrative_area_level_2', 'political'],
          short_name: 'Comuna 14',
          long_name: 'Comuna 14',
        },
        {
          types: ['administrative_area_level_1', 'political'],
          short_name: 'CABA',
          long_name: 'Buenos Aires',
        },
        {
          types: ['country', 'political'],
          short_name: 'AR',
          long_name: 'Argentina',
        },
        {
          long_name: 'C1414',
          short_name: 'C1414',
          types: ['postal_code'],
        },
      ],
      formatted_address: 'Av. Cnel. Niceto Vega 5656, C1414 CABA, Argentina',
      shippings: ['ITZDTsFT0HdpDD3Rxaf6'],
      last_used: {
        _seconds: 1596739196,
        _nanoseconds: 715000000,
      },
      geometry: {
        location: {
          lng: -58.4399702,
          lat: -34.5863116,
        },
      },
      users: ['RZhwHX9RU8YQwoSFybs07uDm2Vy2'],
      place_id: 'ChIJpdKeLY21vJURxHu-AON6yoU',
    },
  },
  seller: 'v',
  shipping: 'ITZDTsFT0HdpDD3Rxaf6',
  discount: 0,
  mp_comission: 0,
  shipping_cost: 350,
  subTotal: 632.2314049586778,
  store: false,
  items: {
    nyR23tEzgAfobNza3zyS: {
      q: 1,
      s: 'v',
      price: 301.6528925619835,
      timestamp: {
        _seconds: 1596738475,
        _nanoseconds: 29000000,
      },
    },
    jVnglk7JyZBudu6QRRxd: {
      s: 'v',
      price: 54.54545454545455,
      timestamp: {
        _seconds: 1596738290,
        _nanoseconds: 415000000,
      },
      q: 1,
    },
    zJjDkm9g7GPN4VbD1Rjg: {
      timestamp: {
        _seconds: 1596737881,
        _nanoseconds: 889000000,
      },
      q: 1,
      s: 'v',
      price: 108.2644628099174,
    },
    bF2lbJjfClz058cKxdN2: {
      s: 'v',
      timestamp: {
        _seconds: 1596738381,
        _nanoseconds: 445000000,
      },
      price: 119.0082644628099,
      q: 1,
    },
    WVWSSHHOWEw9nhpip4dA: {
      price: 48.7603305785124,
      s: 'v',
      q: 1,
      timestamp: {
        _seconds: 1596738926,
        _nanoseconds: 90000000,
      },
    },
  },
  timestamp: {
    _seconds: 1596740040,
    _nanoseconds: 727000000,
  },
  copied: false,
  status: 'pending_payment',
  porcentDiscount: 0,
  refunded: 0,
  mercadoPagoId: '314922091-be184b07-2737-44dc-8b79-f774136417ff',
  approved: true,
  messageSent: true,
  __v: 0,
  modified_date: '2020-08-06T18:54:02.881Z',
  objectID: 'NMnSmKdT23xPjifnJvIp',
}
